import { Stack } from "@mantine/core";
import { Comment } from "../../comment/comment";

interface IAgentProps {
  limit?: number;
}

export const CommentLoader = ({ limit = 1 }: IAgentProps) => {
  return (
    <Stack gap="md" px="lg">
      {Array(limit)
        .fill("*")
        .map((_, i) => (
          <Comment
            key={i}
            fullName="Jane Doe"
            date={new Date().toISOString()}
            message="https://images.unsplash.com/photo-1592520113018-180c8bc831c9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
            skeleton={true}
          />
        ))}
    </Stack>
  );
};
