import React, { useState, useMemo, useEffect } from "react";
import { Box, Group, Text, Stack, Flex, em, Image } from "@mantine/core";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateCertificateRequestDTO } from "../../../../../schema/certificates";
import {
  CertificateType,
  BiodataType,
  ServiceCodes,
} from "../../../../../enums/enums";
import { CreateCertificateRequestSchema } from "../../../../../schema/certificates";
import DropdownIcon from "../../../../assets/svg/dropDownIcon.svg";
import CertificateFormData from "./certificateFormData";
import OrangeButton from "../../../shared/buttons/orangeButton";
import { useGetAllServices } from "../../../../../hooks/user/user.services";
import { useCreateCertificateRequest } from "../../../../../hooks/certificates/user.certificates.hook";
import { useCurrentWorkspace } from "../../../../../hooks/user/user.workspace.hook";
import UserLayout from "../../../../layouts/userLayout";
import PreviewRequest from "./previewRequest";
import WorkspaceRBAC from "../settings/workspace/rbac";
import ServicesLoader from "../../../shared/skeletonLoader/servicesLoader";
import { useCurrentCountry } from "../../../../../hooks/country";

function CertificateVerification() {
  const { workspaceId } = useCurrentWorkspace();
  const { data: servicesData, isPending: isServicesPending } =
    useGetAllServices();
  const { countryData } = useCurrentCountry();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { mutate, isPending } = useCreateCertificateRequest(workspaceId!);
  const [title, setTitle] = useState("");
  const [certificate, setCertificate] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [servicePrice, setServicePrice] = useState<string>("0");
  const [headerData, setHeaderData] = useState(["Certificate Verification"]);

  const initialFormData = useMemo(() => {
    return {
      certificates: [
        {
          subjectName: "",
          applicantName: "",
          matricNo: "",
          university: "",
          registrationNo: "",
          documentName: "",
          professionalBody: "",
          licenseNo: "",
          serviceYear: undefined,
          nyscNo: "",
          certificateNo: "",
          serviceState: "",
          stateCode: "",
          verificationReason: "",
          consentUrl: "",
          certificateUrl: "",
          biodataType: "",
          certificateType: certificate,
        },
      ],
    };
  }, [certificate]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateCertificateRequestDTO>({
    defaultValues: initialFormData,
    resolver: yupResolver(CreateCertificateRequestSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "certificates",
  });
  const watchCertificate = watch("certificates");

  const controlledCertificates = fields.map((certificate, index) => {
    return {
      ...certificate,
      ...watchCertificate[index],
    };
  });

  useEffect(() => {
    fields.forEach((_, index) => {
      setValue(`certificates.${index}.certificateType`, certificate);
    });
  }, [certificate, setValue, fields]);

  const calculatePrice = (price: string, length: number) => {
    return Number(price) * length;
  };

  const onSubmit: SubmitHandler<CreateCertificateRequestDTO> = (data) => {
    mutate({
      certificates: data.certificates.map((item) => ({
        ...item,
        certificateType: certificate,
      })),
      country: countryData!,
    });
  };

  const onPreview: SubmitHandler<CreateCertificateRequestDTO> = () => {
    setTotalPrice(calculatePrice(servicePrice, controlledCertificates.length));
    setIsPreviewOpen(true);
  };

  const handleAddMore = () => {
    append(initialFormData.certificates[0]);
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleTitleChange = (value: string) => {
    reset();
    setIsPreviewOpen(false);
    if (value && value !== title) {
      setTitle(value);
      setHeaderData(["Certificate Verification", value]);
    } else {
      setTitle("");
      setHeaderData(["Certificate Verification"]);
    }
  };

  const handleCertificateChange = (value: string, price: string) => {
    reset();
    setServicePrice(price);
    setIsPreviewOpen(false);
    if (value && value !== certificate) {
      setCertificate(value);
      setHeaderData(["Certificate Verification", title, value]);
      if (title !== CertificateType.BIODATA) {
        setValue(`certificates.0.certificateType`, value);
      } else if (title === CertificateType.BIODATA) {
        setValue(`certificates.0.certificateType`, CertificateType.BIODATA);
        setValue(`certificates.0.biodataType`, value);
      }
    } else {
      setCertificate("");
      setHeaderData(["Certificate Verification", title]);
    }
  };

  const DataTypes = [
    {
      title: "ACADEMICS",
      subTypes: [
        {
          label: "UNIVERSITY",
          value: CertificateType.UNIVERSITY,
          serviceCode: ServiceCodes.UNIVERSITY,
        },
        {
          label: "WAEC",
          value: CertificateType.WAEC,
          serviceCode: ServiceCodes.WAEC,
        },
        {
          label: "NECO",
          value: CertificateType.NECO,
          serviceCode: ServiceCodes.NECO,
        },
        {
          label: "OTHERS",
          value: CertificateType.OTHERS,
          serviceCode: ServiceCodes.OTHERS_CERTIFICATE,
        },
      ],
    },
    {
      title: "PROFESSIONAL",
      subTypes: [
        {
          label: "PROFESSIONAL",
          value: CertificateType.PROFESSIONAL,
          serviceCode: ServiceCodes.PROFESSIONAL,
        },
      ],
    },
    {
      title: "NYSC",
      subTypes: [
        {
          label: "NYSC",
          value: CertificateType.NYSC,
          serviceCode: ServiceCodes.NYSC,
        },
      ],
    },
    {
      title: CertificateType.BIODATA,
      subTypes: [
        {
          label: "Birth Certificate",
          value: BiodataType.DOB,
          serviceCode: ServiceCodes.BIODATA,
        },
        {
          label: "Marriage Certificate",
          value: BiodataType.MARRIAGE,
          serviceCode: ServiceCodes.BIODATA,
        },
        {
          label: "Death Certificate",
          value: BiodataType.DEATH,
          serviceCode: ServiceCodes.BIODATA,
        },
        {
          label: "OTHERS",
          value: BiodataType.OTHERS,
          serviceCode: ServiceCodes.BIODATA,
        },
      ],
    },
  ];

  const DataTypesWithAvailability = DataTypes.map((dataType) => {
    const updatedSubTypes = dataType?.subTypes.map((subType) => {
      const matchingEntry = servicesData?.data.find(
        (entry: any) => entry.serviceCode === subType?.serviceCode
      );
      if (matchingEntry) {
        return {
          ...subType,
          price:
            countryData === "NG"
              ? matchingEntry?.nairaAmount
              : matchingEntry?.dollarAmount,
          id: matchingEntry?.id,
          available: matchingEntry.available || false,
          visible: matchingEntry.visible || false,
        };
      } else {
        return {
          ...subType,
          id: matchingEntry?.id,
          available: false,
          visible: false,
          price: matchingEntry?.costAmount,
        };
      }
    });

    return {
      ...dataType,
      subTypes: updatedSubTypes,
    };
  });

  const visibleDataTypes = DataTypesWithAvailability?.filter((dataType) =>
    dataType?.subTypes?.some((subType) => subType?.visible)
  );

  // const handleTitleChange = useCallback(
  //   (value: string) => {
  //     reset();
  //     setIsPreviewOpen(false);
  //     if (value && value !== title) {
  //       setTitle(value);
  //       setHeaderData(["Certificate Verification", value]);
  //     } else {
  //       setTitle("");
  //       setHeaderData(["Certificate Verification"]);
  //     }
  //   },
  //   [reset, title, setTitle, setHeaderData, setIsPreviewOpen]
  // );

  // const handleCertificateChange = useCallback(
  //   (value: string, price: string) => {
  //     reset();
  //     setServicePrice(price);
  //     setIsPreviewOpen(false);
  //     setTitle(title);
  //     if (value && value !== certificate) {
  //       setCertificate(value);
  //       setHeaderData(["Certificate Verification", title, value]);
  //       if (title !== CertificateType.BIODATA) {
  //         setValue(`certificates.0.certificateType`, value);
  //       } else if (title === CertificateType.BIODATA) {
  //         setValue(`certificates.0.certificateType`, CertificateType.BIODATA);
  //         setValue(`certificates.0.biodataType`, value);
  //       }
  //     } else {
  //       setCertificate("");
  //       setHeaderData(["Certificate Verification", title]);
  //     }
  //   },
  //   [
  //     reset,
  //     certificate,
  //     title,
  //     setCertificate,
  //     setHeaderData,
  //     setIsPreviewOpen,
  //     setServicePrice,
  //     setValue,
  //     setTitle,
  //   ]
  // );

  // const DataTypes = useMemo(
  //   () => [
  //     {
  //       title: "ACADEMICS",
  //       subTypes: [
  //         {
  //           label: "UNIVERSITY",
  //           value: CertificateType.UNIVERSITY,
  //           serviceCode: ServiceCodes.UNIVERSITY,
  //         },
  //         {
  //           label: "WAEC",
  //           value: CertificateType.WAEC,
  //           serviceCode: ServiceCodes.WAEC,
  //         },
  //         {
  //           label: "NECO",
  //           value: CertificateType.NECO,
  //           serviceCode: ServiceCodes.NECO,
  //         },
  //         {
  //           label: "OTHERS",
  //           value: CertificateType.OTHERS,
  //           serviceCode: ServiceCodes.OTHERS_CERTIFICATE,
  //         },
  //       ],
  //     },
  //     {
  //       title: "PROFESSIONAL",
  //       subTypes: [
  //         {
  //           label: "PROFESSIONAL",
  //           value: CertificateType.PROFESSIONAL,
  //           serviceCode: ServiceCodes.PROFESSIONAL,
  //         },
  //       ],
  //     },
  //     {
  //       title: "NYSC",
  //       subTypes: [
  //         {
  //           label: "NYSC",
  //           value: CertificateType.NYSC,
  //           serviceCode: ServiceCodes.NYSC,
  //         },
  //       ],
  //     },
  //     {
  //       title: CertificateType.BIODATA,
  //       subTypes: [
  //         {
  //           label: "Birth Certificate",
  //           value: BiodataType.DOB,
  //           serviceCode: ServiceCodes.BIODATA,
  //         },
  //         {
  //           label: "Marriage Certificate",
  //           value: BiodataType.MARRIAGE,
  //           serviceCode: ServiceCodes.BIODATA,
  //         },
  //         {
  //           label: "Death Certificate",
  //           value: BiodataType.DEATH,
  //           serviceCode: ServiceCodes.BIODATA,
  //         },
  //         {
  //           label: "OTHERS",
  //           value: BiodataType.OTHERS,
  //           serviceCode: ServiceCodes.BIODATA,
  //         },
  //       ],
  //     },
  //   ],
  //   []
  // );

  // const DataTypesWithAvailability = useMemo(() => {
  //   return DataTypes.map((dataType) => {
  //     const updatedSubTypes = dataType?.subTypes.map((subType) => {
  //       const matchingEntry = servicesData?.data.find(
  //         (entry: any) => entry?.serviceCode === subType?.serviceCode
  //       );
  //       if (matchingEntry) {
  //         return {
  //           ...subType,
  //           price: matchingEntry?.costAmount,
  //           id: matchingEntry?.id,
  //           available: matchingEntry?.available || false,
  //           visible: matchingEntry?.visible || false,
  //         };
  //       } else {
  //         return {
  //           ...subType,
  //           id: matchingEntry?.id,
  //           available: false,
  //           visible: false,
  //           price: matchingEntry?.costAmount,
  //         };
  //       }
  //     });

  //     return {
  //       ...dataType,
  //       subTypes: updatedSubTypes,
  //     };
  //   });
  // }, [DataTypes, servicesData]);

  // const visibleDataTypes = useMemo(() => {
  //   return DataTypesWithAvailability?.filter((dataType) =>
  //     dataType?.subTypes?.some((subType) => subType?.visible)
  //   );
  // }, [DataTypesWithAvailability]);

  return (
    <UserLayout title="Services">
      <Stack p={0}>
        <Group gap={"0em"}>
          {headerData.map((data, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <span style={{ marginRight: "0.5em" }}></span>}
              <Text
                c={index === headerData.length - 1 ? "dark.4" : "light-grey.8"}
                {...(index === headerData.length - 1 && { fw: 500 })}
                key={index}
              >
                {index === 0
                  ? data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
                  : " > " +
                    data.charAt(0).toUpperCase() +
                    data.slice(1).toLowerCase()}
              </Text>
            </React.Fragment>
          ))}
        </Group>
        <Flex
          gap={"2.5em"}
          wrap={"wrap"}
          direction={{
            base: "column",
            sm: "row",
            md: "row",
          }}
          align={{ base: "center", sm: "flex-start" }}
        >
          <Box
            style={{
              borderRadius: "1.5em",
              border: "1.30px solid #E8E8E8",
              boxShadow: "box-shadow: 0px 4px 16px 0px #0000000D",
            }}
            bg={"light-grey.4"}
            w={"370px"}
            pt={"3em"}
            pb={"1.5em"}
          >
            <Stack gap={"0em"}>
              <Group p={"1em 1.5em"} bg={"light-grey.3"} w={"100%"}>
                <Text fz={em(14)} fw={500} w={"10%"} c={"light-grey.8"}>
                  SRNO
                </Text>
                <Text fz={em(14)} fw={500} c={"light-grey.8"}>
                  SERVICES
                </Text>
              </Group>
              {!isServicesPending && (
                <>
                  {visibleDataTypes?.map((data, index) => (
                    <Group
                      key={index}
                      onClick={() => handleTitleChange(data.title)}
                      p={"1em 0"}
                      w={"100%"}
                      style={{
                        borderBottom: "0.5px solid #E8E8E8",
                        cursor: "pointer",
                      }}
                    >
                      <Text pl={"1.5em"} fw={600} w={"10%"}>
                        {index + 1}
                      </Text>
                      <Group justify={"space-between"} w={"80%"} pr={"1.5em"}>
                        <Text fw={600}>{data.title}</Text>
                        <Image
                          // onClick={)}
                          style={{
                            transition: "transform 0.2s ease-in-out",
                            transform:
                              data.title === title
                                ? "rotate(0deg)"
                                : "rotate(-90deg)",
                          }}
                          w={"10"}
                          h={"10"}
                          src={DropdownIcon}
                          alt="dropdown"
                        />
                      </Group>
                      {title === data.title && (
                        <Stack
                          gap={"0em"}
                          style={{
                            marginLeft: "0em",
                            width: "100%",
                            paddingTop: "0.5em",
                            borderTop: "0.5px solid #E8E8E8",
                          }}
                        >
                          {data.subTypes.map(
                            (subType, index) =>
                              subType.visible && (
                                <Text
                                  style={{
                                    textDecoration: subType.available
                                      ? ""
                                      : "line-through",
                                    paddingTop: "0.75em",
                                    paddingBottom: "0.75em",
                                    paddingLeft:
                                      certificate === subType.value
                                        ? "13.5%"
                                        : "15%",
                                    cursor: "pointer",
                                    borderLeft:
                                      certificate === subType.value
                                        ? "5px solid #E86217"
                                        : "",
                                    backgroundColor:
                                      certificate === subType.value
                                        ? "#FFF2EB"
                                        : "",
                                  }}
                                  onClick={() =>
                                    subType?.available
                                      ? handleCertificateChange(
                                          subType.value,
                                          subType.price
                                        )
                                      : null
                                  }
                                  key={index}
                                  c={"light-grey.8"}
                                  fw={600}
                                >
                                  {subType.label}
                                </Text>
                              )
                          )}
                        </Stack>
                      )}
                    </Group>
                  ))}
                </>
              )}
              {isServicesPending && <ServicesLoader />}
            </Stack>
          </Box>

          {!isPreviewOpen && (
            <Stack
              gap={"0.1em"}
              miw={"370px"}
              ml={"1em"}
              w={{ base: "370px", md: "420px" }}
              style={{
                overflowY: "auto",
                maxHeight: "80vh",
                scrollbarWidth: "none",
                scrollbarColor: "transparent transparent",
              }}
            >
              {certificate !== "" && (
                <CertificateFormData
                  setValue={setValue}
                  active={certificate}
                  certificates={controlledCertificates}
                  handleRemove={handleRemove}
                  errors={errors}
                  register={register}
                  getValues={getValues}
                />
              )}
              {certificate !== "" && (
                <>
                  <Text
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={handleAddMore}
                  >
                    + Add More
                  </Text>
                  <WorkspaceRBAC action={"canMakeRequests"}>
                    <div
                      style={{
                        padding: "0em 2em",
                      }}
                    >
                      <OrangeButton
                        buttonText={"Preview"}
                        onClick={handleSubmit(onPreview)}
                        // onClick={ () => {
                        //   setTotalPrice(calculatePrice(servicePrice, controlledCertificates.length));
                        //   setIsPreviewOpen(true);
                        // }}
                      />
                    </div>
                  </WorkspaceRBAC>
                </>
              )}
            </Stack>
          )}

          {isPreviewOpen && (
            <PreviewRequest
              totalPrice={totalPrice}
              active={certificate}
              certificates={controlledCertificates}
              onBack={() => setIsPreviewOpen(false)}
              onSubmit={handleSubmit(onSubmit)}
              isPending={isPending}
            />
          )}
        </Flex>
      </Stack>
    </UserLayout>
  );
}

export default React.memo(CertificateVerification);
