import { useLocalStorage } from "@mantine/hooks";
import { useEffect, useState } from "react";

export const useCurrentCountry = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useLocalStorage<string | null>({
    key: "kpali-user-country",
    defaultValue: localStorage.getItem("kpali-user-country") || null,
  });

  useEffect(() => {
    if (isLoading) return;
    const fetchCountry = async () => {
      setIsLoading(true);

      try {
        if (!countryData) {
          const response = await fetch(
            "https://ipinfo.io/json?token=c94ad0e2e1bd9f"
          );
          if (!response.ok) {
            throw new Error("Failed to fetch country data");
          }
          const data = await response.json();
          const countryCode = data.country;

          // Save country data in localStorage
          setCountryData(countryCode);
        }
      } catch (error) {
        console.error("Error fetching country data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountry();
  }, [countryData, setCountryData]);

  return { countryData, isLoading };
};
