import API from "../../../api/api";

const URL = "admin/requests";

interface ICreateComments {
  requestId: string;
  data: any;
}

export const createRequestCommentsApi = async ({
  requestId,
  data,
}: ICreateComments) => {
  return await API.post(`${URL}/${requestId}/comments`, data).then(
    (response) => response.data
  );
};
