import { Avatar, Group, Skeleton, Stack, Text } from "@mantine/core";
import moment from "moment";
import { getNameInitials } from "../../../utils/utils";

interface ICommentProps {
  message: string;
  fullName?: string;
  date: string;
  skeleton?: boolean;
}

export const Comment = ({
  message,
  fullName,
  date,
  skeleton = false,
}: ICommentProps) => {
  const getRelativeTime = () => {
    const now = moment();
    const diffMinutes = now.diff(moment(date), "minutes");

    if (diffMinutes < 60) {
      return `${diffMinutes} minutes ago`;
    } else if (diffMinutes < 1440) {
      return `${now.diff(moment(date), "hours")} hours ago`;
    } else if (diffMinutes < 43200) {
      return `${now.diff(moment(date), "days")} days ago`;
    } else {
      return moment(date).fromNow();
    }
  };
  return (
    <Group
      align="flex-start"
      py={"md"}
      px="xl"
      style={{
        "&:hover": {
          bg: "gray",
        },
      }}
    >
      <Skeleton
        circle={true}
        visible={skeleton}
        p={0}
        m={0}
        height={40}
        width={40}
      >
        <Avatar color="indigo" radius="xl">
          {getNameInitials({ name: fullName!, maxInitials: 2 })}
        </Avatar>
      </Skeleton>
      <Stack gap={"xs"}>
        <Skeleton visible={skeleton}>
          <Group>
            <Text size="sm" w={700} tt="capitalize">
              {fullName}
            </Text>
            <Text
              size="xs"
              style={(theme) => ({
                color: theme.colors.gray[6],
              })}
            >
              {getRelativeTime()}
            </Text>
          </Group>
        </Skeleton>
        <Skeleton visible={skeleton} width={300}>
          <Text size="xs" style={() => ({ fontSize: 14 })}>
            {message}
          </Text>
        </Skeleton>
      </Stack>
    </Group>
  );
};
