import * as Yup from "yup";

export const AddAgentSchema = Yup.object().shape({
  userId: Yup.string().trim().optional().nullable(),
  firstName: Yup.string().trim().optional(),
  lastName: Yup.string().trim().optional(),
  email: Yup.string().email().trim().optional(),
  phoneNo: Yup.string().trim().optional(),
  institution: Yup.string().trim().optional(),
  amount: Yup.number().min(0, "Amount must be a positive number").optional(),
});

export type AddAgentDto = Yup.InferType<typeof AddAgentSchema>;
