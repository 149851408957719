import { useState } from "react";
import { Button, Modal, Text, Group } from "@mantine/core";
import useResponsive from "../../../../../../hooks/useResponsive";
import CustomTable from "../../../../shared/table/customTable";
import TransparentTable from "../../../../shared/table/transparentTable";
import { CustomCheckBox, CustomSearchInput } from "../../../../shared/inputs";
import { useGetAvailableAgents } from "../../../../../../hooks/admin/agents.hook";
import { AgentRequestDetails } from "../../../../shared/table/agentRequestDetails";
import { useMutation } from "react-query";
import { assignRequestToAgent } from "../../../../../../hooks/apis/admin/agents";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

function AgentSection({ requestDetails }: { requestDetails: any }) {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const { isMobile } = useResponsive();
  const { data } = useGetAvailableAgents(page, 5, search);
  const [active, setActive] = useState(null as any);
  const availableAgents = data?.data?.data;
  const availableAgentsCount = data?.data?.count;
  const availableAgentsTotalPages = data?.data?.totalPages;
  const availableAgentsCurrentPage = data?.data?.currentPage;

  const assignToAgent = useMutation({
    mutationFn: assignRequestToAgent,
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
    onError: (err: AxiosError) => {
      toast.error(err.message ?? "Unable to assign request to agent");
    },
  });

  const TableData = availableAgents?.map((agent: any, index: number) => {
    return {
      checbox: <CustomCheckBox />,
      institution: agent?.institution,
      email: agent?.email,
      name: agent?.businessName || agent?.firstName + " " + agent?.lastName,
      phone: agent?.phoneNo,
      action: (
        <Button
          disabled={!agent?.email}
          onClick={() => {
            setActive(agent);
          }}
        >
          Assign
        </Button>
      ),
    };
  });

  const agentlabels = [
    "Institution",
    "Name",
    "Email",
    "Phone",
    "Current Tasks",
    "Tasks Completed",
  ];
  const agentValues = [
    active?.institution,
    active?.businessName || active?.firstName + " " + active?.lastName,
    active?.email,
    active?.phoneNo,
    active?.currentTasks || 0,
    active?.tasksCompleted || 0,
  ];

  return (
    <>
      <Modal
        opened={active !== null}
        fullScreen={isMobile}
        onClose={() => setActive(null)}
        title={
          <Text c={"dark.4"} fw={600} fz={"24px"}>
            Assign Agent
          </Text>
        }
      >
        <Modal.Body>
          <Text c={"light-grey.8"}>AGENT</Text>
          <TransparentTable labels={agentlabels} values={agentValues} />
          {/* <Divider c={"#E8E8E8"} /> */}
          <Text c={"light-grey.8"}>TASK</Text>
          <AgentRequestDetails
            request={requestDetails.request.certificates[0]}
          />
          <Group justify="space-between">
            <Button
              bg={"light-grey.5"}
              c={"dark.4"}
              size="md"
              radius={"8px"}
              onClick={() => setActive(null)}
            >
              Cancel
            </Button>
            <Button
              bg={"blue.0"}
              disabled={assignToAgent.isLoading}
              radius={"8px"}
              size="md"
              onClick={() =>
                assignToAgent.mutate({
                  institution:
                    requestDetails.request.certificates[0].university,
                  subjectName:
                    requestDetails.request.certificates[0].subjectName,
                  content:
                    requestDetails.request.certificates[0].certificateUrl,
                  email: active?.email,
                  docType:
                    requestDetails.request.certificates[0].certificateType,
                })
              }
            >
              Assign
            </Button>
          </Group>
        </Modal.Body>
      </Modal>
      <CustomTable
        leftItem={<Text c={"light-grey.8"}>Available Agents</Text>}
        rightItem={
          <CustomSearchInput
            setSearch={setSearch}
            placeholder={"Serch by institution, email"}
          />
        }
        currentPage={availableAgentsCurrentPage}
        totalPages={availableAgentsTotalPages}
        setPage={setPage}
        count={availableAgentsCount}
        bodyRows={TableData}
        minWidth={"850px"}
        labels={[
          <CustomCheckBox />,
          "Institution",
          "Email",
          "Name",
          "Phone",

          "Action",
        ]}
      />
    </>
  );
}

export default AgentSection;
