import { Box, Flex, Image, Stack, Text } from "@mantine/core";

interface TeamCardProps {
  profile1Image: string;
  name: string;
  position: string;
}

function TeamCard({ profile1Image, name, position }: TeamCardProps) {
  return (
    <Flex
      direction={"column"}
      gap={"0.8em"}
      pt={{ base: "3em", md: "6em" }}
      align={"center"}
      // justify={"center"}
    >
      <Box
        h={{
          base: "215px",
          md: "280px",
        }}
        w={{
          base: "180px",
          md: "235px",
        }}
      >
        <Image
          style={{
            border: "none",
          }}
          fit="fill"
          src={profile1Image}
          width={"100%"}
          height={"100%"}
        />
      </Box>
      <Stack gap={0}>
        <Text fz={"lg"} fw={{ base: 500, sm: 700 }}>
          {name}
        </Text>
        <Text fz={"md"} fw={400}>
          {position}
        </Text>
      </Stack>
    </Flex>
  );
}

export default TeamCard;
