import { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import AdminLayout from "../../../../layouts/adminLayout";
import { ActitonButton } from "../users/users";
import {
  CustomCheckBox,
  CustomSearchInput,
  CustomTextInput,
} from "../../../shared/inputs";
import GhostIcon from "../../../../assets/svg/ghostIcon.svg";
import CustomTable from "../../../shared/table/customTable";
import {
  useGetAvailableAgents,
  useAddAgent,
} from "../../../../../hooks/admin/agents.hook";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddAgentDto, AddAgentSchema } from "../../../../../schema/admin/agent";
import { SubmitHandler, useForm } from "react-hook-form";
import { useGetAllUsers } from "../../../../../hooks/admin/user.hook";

function Agents() {
  const [search, setSearch] = useState("");
  const [searchUsers, setSearchUsers] = useState("");
  const [page, setPage] = useState(1);
  const [isAddAgentOpen, setIsAddAgentOpen] = useState(false);
  const { mutate: addAgent, isPending: isAddingAgent } = useAddAgent();
  const { data, isLoading } = useGetAvailableAgents(page, 40, search);
  const { data: usersData, isLoading: usersLoading } = useGetAllUsers(
    1,
    10,
    searchUsers
  );
  const availableAgents = data?.data?.data;
  const availableAgentsCount = data?.data?.count;
  const availableAgentsTotalPages = data?.data?.totalPages;
  const availableAgentsCurrentPage = data?.data?.currentPage;

  const userDropdownData = usersData?.data?.data?.map((user: any) => {
    return {
      value: user?.email,
      label: user?.email,
    };
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<AddAgentDto>({
    defaultValues: {
      userId: null,
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      institution: "",
      amount: 0,
    },
    resolver: yupResolver(AddAgentSchema),
  });

  const selected = usersData?.data?.data?.find(
    (user: any) => user?.email === searchUsers
  );

  useEffect(() => {
    if (selected) {
      setValue("userId", selected?.id);
      setValue("firstName", selected?.firstName || selected?.businessName);
      setValue("lastName", selected?.lastName);
      setValue("email", selected?.email);
      setValue("phoneNo", selected?.phoneNo);
    }
  }, [selected, setValue]);

  const onSubmit: SubmitHandler<AddAgentDto> = (data) => {
    addAgent(data, {
      onSuccess: () => {
        reset();
        setIsAddAgentOpen(false);
      },
    });
  };

  const TableData = availableAgents?.map((agent: any) => {
    return {
      checbox: <CustomCheckBox />,
      institution: agent?.institution,
      email: agent?.email,
      name: agent?.businessName || agent?.firstName + " " + agent?.lastName,
      phone: agent?.phoneNo,
      amount: agent?.amount,
      action: <ActitonButton />,
    };
  });

  useEffect(() => {
    // Reset page to 1 when search changes
    setPage(1);
  }, [search]);

  return (
    <AdminLayout title="Agents">
      <CustomTable
        title={"Available Agents"}
        rightItem={
          <Group>
            <CustomSearchInput
              setSearch={setSearch}
              placeholder={"Search by institution, email"}
            />
            <Button onClick={() => setIsAddAgentOpen(true)}>
              <IoAdd size={20} />
            </Button>
          </Group>
        }
        isTableLoading={isLoading}
        currentPage={availableAgentsCurrentPage}
        totalPages={availableAgentsTotalPages}
        setPage={setPage}
        count={availableAgentsCount}
        bodyRows={TableData}
        minWidth={"850px"}
        labels={[
          <CustomCheckBox />,
          "Institution",
          "Email",
          "Name",
          "Phone",
          "Amount",
          "Action",
        ]}
        emptyState={{
          title: "No Agent Found",
          description:
            "They are currently no agents available on Kpali, please onboard new agents to get started.",
          image: GhostIcon,
          height: "100px",
          width: "175px",
        }}
      />
      <Modal
        opened={isAddAgentOpen}
        onClose={() => setIsAddAgentOpen(false)}
        title="Add Agent"
        size="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={20} style={{ padding: "20px" }}>
            <CustomSearchInput
              iconRight={null}
              // label={"Search User"}
              label={usersLoading ? "Loading Users" : "Search User"}
              setSearch={setSearchUsers}
              data={userDropdownData}
              placeholder={"Search by name, email, phone, location"}
            />
            <CustomTextInput
              label={"First Name"}
              error={errors.firstName?.message}
              register={register("firstName")}
              notRequired
            />
            <CustomTextInput
              label={"Last Name"}
              error={errors.lastName?.message}
              register={register("lastName")}
              notRequired
            />
            <CustomTextInput
              label={"Email"}
              error={errors.email?.message}
              register={register("email")}
              notRequired
            />
            <CustomTextInput
              label={"Phone Number"}
              error={errors.phoneNo?.message}
              register={register("phoneNo")}
              notRequired
            />
            <CustomTextInput
              label={"Institution"}
              error={errors.institution?.message}
              register={register("institution")}
              notRequired
            />
            <CustomTextInput
              label={"Amount"}
              error={errors.amount?.message}
              register={register("amount")}
              notRequired
            />
            <Button
              type="submit"
              loading={isAddingAgent}
              disabled={isAddingAgent}
            >
              Add Agent
            </Button>
          </Stack>
        </form>
      </Modal>
    </AdminLayout>
  );
}

export default Agents;
