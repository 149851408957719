import { Button, Stack } from "@mantine/core";
import RequestDataLayout from "../../dashboard/user/service/requestDataLayout";

export const AgentRequestDetails = ({ request }: { request: any }) => {
  return (
    <Stack gap={"0.5em"}>
      <RequestDataLayout label="Institution" data={request.university} />
      <RequestDataLayout
        label="certificate Type"
        data={request.certificateType}
      />
      <RequestDataLayout label="subjectName" data={request.subjectName} />
      <RequestDataLayout label="status" data={request.status} />
      <RequestDataLayout
        label="Certificate"
        data={
          <Button
            variant="transparent"
            component="a"
            target="_blank"
            href={request.certificateUrl}
          >
            preview
          </Button>
        }
      />
    </Stack>
  );
};
