import API from "../../../api/api";

const URL = "/admin/agents";

export const getAllAgentsByAdmin = async () => {
  const response = await API.get(`${URL}`).then((response) => response.data);
  return response;
};

export const assignRequestToAgent = async (data: any) => {
  const response = await API.post(`${URL}/assignment`, data).then(
    (response) => response
  );
  return response;
};
