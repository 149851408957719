export const getNameInitials = (params: {
  name: string | undefined;
  maxInitials?: number;
}) => {
  if (!params.name) return "";
  return params.name
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((value) => !!value)
    .slice(0, params.maxInitials)
    .join("")
    .toUpperCase();
};
