import { useQuery } from "react-query";
import API from "../../api/api";

const URL = "admin/requests";
export const useGetRequestComments = (requestId: string) => {
  const getRequestComments = async (): Promise<any[]> => {
    try {
      const response = await API.get(`${URL}/${requestId}/comments`);
      return response.data;
    } catch (error) {
      throw new Error(
        `Error fetching comments for request ${requestId}: ${error}`
      );
    }
  };

  const query = useQuery({
    queryFn: getRequestComments,
    queryKey: ["comments", requestId],
    refetchOnMount: true,
  });

  return query;
};
