import {
  Button,
  Container,
  SimpleGrid,
  Stack,
  Divider,
  Box,
  Title,
  em,
  Group,
} from "@mantine/core";
import { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import GreyBackground from "../backgrounds/greyBackground";
import PriceCard from "../cards/priceCard";
import LandingSectionHeader from "../sectionHeading/landing";
import { certificates } from "./certificateCards";
import { useCurrentCountry } from "../../../hooks/country";
import { useGetAllServices } from "../../../hooks/user/user.services";

function Services() {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const [isCertificateOpen, setIsCertificateOpen] = useState(true);
  const { data: servicesRes, isLoading } = useGetAllServices();

  const { countryData } = useCurrentCountry();
  const [servicesToDisplay, setServicesToDisplay] = useState(3);

  const updatedCertificates = () => {
    return servicesRes?.data.map((service: any) => {
      // Find matching certificate based on ServiceCode
      const certsData = certificates.find((certificate) => {
        return service.serviceCode === certificate.ServiceCode;
      });

      // If certsData is found, extend service with additional fields, otherwise return service as is
      if (certsData) {
        return {
          ...service,
          duration: certsData?.Duration,
          image: certsData?.Image,
          link: certsData?.Link,
        };
      }

      // Return the original service object if no match is found
      return service;
    });
  };

  const servicesUpdated = updatedCertificates();

  const certificatesServices = servicesUpdated?.filter(
    (service: any) => service.serviceCategory === "CERTIFICATE"
  );

  return (
    <GreyBackground>
      <Container size={"lg"}>
        <Stack align="center">
          <LandingSectionHeader
            headerText="Certificate Verification Services"
            subText="Our solution stands out with its versatility, accommodating various "
          />
          <Box
            pl={{ base: "", sm: "4em" }}
            pr={{ base: "", sm: "4em" }}
            size={"lg"}
            w={"100%"}
          >
            <Group mt={"5em"} justify={isMobile ? "space-between" : ""}>
              <Stack>
                <Button
                  bg={"none"}
                  c={isCertificateOpen ? "orange" : "dark.5"}
                  onClick={() => {
                    setIsCertificateOpen(true);
                  }}
                >
                  <Title fz={{ base: "0.825em", sm: "1.3em" }}>
                    Certificate Verification
                  </Title>
                </Button>
                {isCertificateOpen && (
                  <Divider color={"orange"} w={"100%"} size={4} />
                )}
              </Stack>

              {/* <Stack>
                <Button
                  bg={"none"}
                  c={isCertificateOpen ? "dark.5" : "orange"}
                  onClick={() => {
                    setIsCertificateOpen(false);
                  }}
                >
                  <Title fz={{ base: "0.825em", sm: "1.3em" }}>
                    Document Verification
                  </Title>
                </Button>
                {!isCertificateOpen && (
                  <Divider color={"orange"} w={"100%"} size={4} />
                )}
              </Stack> */}
            </Group>
            <Divider c={"gray"} w={"100%"} size={1} />
          </Box>
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3 }}
            spacing={"xl"}
            mt={50}
            mb={32}
          >
            {isCertificateOpen &&
              certificatesServices.map((certificate: any) => {
                console.log({
                  certificate,
                });
                return (
                  <PriceCard
                    key={certificate.id}
                    actionTexts={[
                      certificate.duration,
                      certificate.description,
                    ]}
                    imgSrc={certificate.image}
                    serviceType={certificate.name}
                    price={
                      countryData === "NG"
                        ? {
                            amount: certificate.nairaAmount,
                            currency: "N",
                          }
                        : {
                            amount: certificate.dollarAmount,
                            currency: "$",
                          }
                    }
                    to={certificate.link}
                  />
                );
              })}
            {/* // : documents.map((certificate, index) => (
              //     <PriceCard
              //       key={index}
              //       actionTexts={[
              //         certificate.Duration,
              //         certificate.Description,
              //       ]}
              //       imgSrc={certificate.Image}
              //       serviceType={certificate.ServiceName}
              //       price={{ currency: "N", amount: certificate.Price }}
              //       to={certificate.Link}
              //     />
              //   ))} */}
          </SimpleGrid>
        </Stack>
      </Container>
    </GreyBackground>
  );
}

export default Services;
