import API from "../../api/api";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { AddAgentDto } from "../../schema/admin/agent";

const URL = "/admin/agents";

export const useGetAvailableAgents = (
  page: number,
  limit: number,
  searchterm: string = ""
) => {
  const getAvailableAgents = async () => {
    const response =
      await API.get(`${URL}?page=${page}&limit=${limit}&searchterm=${searchterm}
   
    `);
    return response;
  };
  const query = useQuery({
    queryFn: getAvailableAgents,
    queryKey: ["GetAvailableAgents", page, limit, searchterm],
  });

  return query;
};

export const useAddAgent = () => {
  const queryClient = useQueryClient();
  const addAgent = async (data: AddAgentDto) => {
    const response = await API.post(`${URL}/create`, data);
    return response;
  };

  const mutation = useMutation({
    mutationFn: addAgent,
    onSuccess: () => {
      toast.success("Agent added successfully");
      queryClient.invalidateQueries({
        queryKey: ["GetAvailableAgents"],
      });
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(
        typeof error.response.data.message === "string"
          ? error.response.data.message
          : error.response.data.message[0]
      );
    },
  });

  return mutation;
};
