import { ActionIcon, Box, Flex, Group, Stack, Textarea } from "@mantine/core";
import { Send } from "tabler-icons-react";
import { Comment } from "../../../../comment/comment";
import { CommentLoader } from "../../../../shared/skeletonLoader/commentLoader";
import EmptyState from "../../../../shared/emptyState";
import { useGetRequestComments } from "../../../../../../hooks/admin/comments";
import { AnyARecord } from "dns";
import { useMutation, useQueryClient } from "react-query";
import { createRequestCommentsApi } from "../../../../../../hooks/apis/admin/comments";
import { useCallback } from "react";
import { useForm } from "@mantine/form";

const loader = (
  <EmptyState
    title="No Comments"
    width="40px"
    height="40px"
    image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE6_210PanwpeXk2pwGDg14WTR1rp-uYjKHw&s"
    description=""
  />
);

function CommentsSection({ requestDetails }: { requestDetails: any }) {
  const { getInputProps, onSubmit, values, setValues } = useForm({
    initialValues: {
      message: "",
    },
  });

  const queryClient = useQueryClient();
  const createComment = useMutation({
    mutationFn: createRequestCommentsApi,
  });
  const { data: comments, isLoading: isLoadingComments } =
    useGetRequestComments(requestDetails?.request?.id);

  return (
    <Flex
      direction={"column"}
      style={() => ({
        height: "80vh",
        overflow: "hidden",

        width: "60%",
      })}
    >
      <Box
        style={() => ({
          flex: 1,
          overflow: "scroll",
          height: "100%",
        })}
      >
        {isLoadingComments && !comments ? (
          <CommentLoader />
        ) : comments && comments.length > 0 ? (
          comments?.map((comment) => (
            <Comment
              key={comment.id}
              date={comment.createdAt}
              message={comment.message}
              fullName={`${comment?.user?.firstName} ${comment?.user?.lastName}`}
            />
          ))
        ) : (!!comments && comments?.length <= 0) || !isLoadingComments ? (
          loader
        ) : (
          loader
        )}
      </Box>
      <Box
        py="md"
        px="lg"
        style={(theme) => ({
          position: "sticky",
          borderTop: `1px solid ${theme.colors.gray[2]}`,
          bottom: 0,
        })}
      >
        <form
          onSubmit={onSubmit(() => {
            createComment.mutate(
              {
                data: {
                  message: values.message,
                },

                requestId: requestDetails?.request?.id,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries([
                    "comments",
                    requestDetails?.request?.id,
                  ]);
                },
              }
            );
            setValues({ message: "" });
          })}
        >
          <Group>
            <Textarea
              {...getInputProps("message")}
              placeholder="Add comment"
              style={(theme) => ({
                flex: 1,
                fontSize: 14,
                backgroundColor: theme.colors.gray[0],
              })}
            />

            <ActionIcon
              type="submit"
              loading={createComment.isLoading}
              color="orange"
              variant="filled"
              size="lg"
            >
              <Send size={24} />
            </ActionIcon>
          </Group>
        </form>
      </Box>
    </Flex>
  );
}

export default CommentsSection;
