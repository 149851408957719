import * as Yup from "yup";
import {
  CertificateType,
  ICertificateType,
  BiodataType,
  IBiodataType,
} from "../../enums/enums";

const CreateCertificateDTOValidationSchema = Yup.object().shape({
  subjectName: Yup.string().required("Subject name is required"),
  applicantName: Yup.string().required("Applicant name is required"),
  matricNo: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.UNIVERSITY,
    then: () =>
      Yup.string().trim().required("Matriculation number is required"),
  }),
  university: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.UNIVERSITY,
    then: () => Yup.string().trim().required("University name is required"),
  }),
  registrationNo: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.WAEC ||
      certificateType === CertificateType.NECO,
    then: () => Yup.string().trim().required("Registration number is required"),
  }),
  documentName: Yup.string().when(["certificateType", "biodataType"], {
    is: (certificateType: ICertificateType, biodataType: IBiodataType) =>
      certificateType === CertificateType.OTHERS ||
      (certificateType === CertificateType.BIODATA &&
        biodataType === BiodataType.OTHERS),
    then: () => Yup.string().trim().required("Document name is required"),
  }),
  professionalBody: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.PROFESSIONAL,
    then: () => Yup.string().trim().required("Professional body is required"),
  }),
  licenseNo: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.PROFESSIONAL,
    then: () => Yup.string().trim().required("Membrship number is required"),
  }),
  serviceYear: Yup.number().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.NYSC,
    then: () => Yup.number().required("NYSC service year is required"),
  }),
  nyscNo: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.NYSC,
    then: () =>
      Yup.string().trim().required("NYSC identity number is required"),
  }),
  certificateNo: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.NYSC,
    then: () =>
      Yup.string().trim().required("NYSC certificate number is required"),
  }),
  serviceState: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.NYSC,
    then: () => Yup.string().trim().required("NYSC service state is required"),
  }),
  stateCode: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.NYSC,
    then: () => Yup.string().trim().required("NYSC state code is required"),
  }),
  verificationReason: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.NYSC,
    then: () => Yup.string().trim().required("Verification reason is required"),
  }),
  consentUrl: Yup.string().url("Consent letter must be a valid url"),
  certificateUrl: Yup.string()
    .required("Certificate is required")
    .url("Certificate document must be a valid url"),
  biodataType: Yup.string().when("certificateType", {
    is: (certificateType: ICertificateType) =>
      certificateType === CertificateType.BIODATA,
    then: () => Yup.string().trim().required("Biodata type is required"),
  }),
  certificateType: Yup.string()
    .trim()
    .required("Certificate document is required"),
});

export const CreateCertificateRequestSchema = Yup.object().shape({
  certificates: Yup.array()
    .of(CreateCertificateDTOValidationSchema)
    .min(1)
    .required(),
  country: Yup.string().optional(),
});

export type CreateCertificateRequestDTO = Yup.InferType<
  typeof CreateCertificateRequestSchema
>;
