import { Button, Container, SimpleGrid, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import GreyBackground from "../backgrounds/greyBackground";
import PriceCard from "../cards/priceCard";
import WaecImg from "../../assets/images/waec_logo.png";
import UniversityImg from "../../assets/images/university_logo.png";
import NecoImg from "../../assets/images/Neco_logo.png";
import NYSCImage from "../../assets/images/NYSC_logo.png";
import ProfessionalImage from "../../assets/images/certificate_logo.png";
import BioDataImage from "../../assets/images/bioDataImage.png";
import DeathRibbonImage from "../../assets/images/death_ribbon.png";
import BirthImage from "../../assets/images/birth_logo.png";
import MarriageImage from "../../assets/images/marriage_logo.jpg";
import LandingSectionHeader from "../sectionHeading/landing";
import { useGetAllServices } from "../../../hooks/user/user.services";
import { useCurrentCountry } from "../../../hooks/country";

export const certificates = [
  {
    ServiceName: "Waec",
    Price: 20000,
    Duration: "Duration 1-2 working days",
    Description: "Verify the validity of a potential employee's",
    Image: WaecImg,
    Link: "/service/certificate",
    ServiceCode: "WAEC",
  },
  {
    ServiceName: "University Degree",
    Price: 40000,
    Duration: "Duration 1-6 weeks",
    Description: "Verify the validity of a potential employee's",
    Image: UniversityImg,
    Link: "/service/certificate",
    ServiceCode: "UNIVERSITY",
  },
  {
    ServiceName: "Neco Result",
    Price: 20000,
    Duration: "Duration 1-2 working days",
    Description: "Verify the validity of a potential employee's",
    Image: NecoImg,
    Link: "/service/certificate",
    ServiceCode: "NECO",
  },
  {
    ServiceName: "NYSC Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify the validity of a potential employee's",
    Image: NYSCImage,
    Link: "/service/certificate",
    ServiceCode: "NYSC",
  },
  {
    ServiceName: "Professional Certificates",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify any professional certificates ",
    Image: ProfessionalImage,
    Link: "/service/certificate",
    ServiceCode: "PROFESSIONAL",
  },
  {
    ServiceName: "Biodata",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify any birth, marital or death records",
    Image: BioDataImage,
    Link: "/service/certificate",
    ServiceCode: "BIODATA",
  },
  {
    ServiceName: "Death Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify any birth, marital or death records",
    Image: DeathRibbonImage,
    Link: "/service/certificate",
    ServiceCode: "DEATH",
  },
  {
    ServiceName: "Birth Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify any birth, marital or death records",
    Image: BirthImage,
    Link: "/service/certificate",
    ServiceCode: "BIRTH",
  },
  {
    ServiceName: "Marriage Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify any birth, marital or death records",
    Image: MarriageImage,
    Link: "/service/certificate",
    ServiceCode: "MARITAL",
  },
  {
    ServiceName: "Other Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description: "Verify any birth, marital or death records",
    Image: ProfessionalImage,
    Link: "/service/certificate",
    ServiceCode: "OTHERS_CERTIFICATE",
  },
];

export function CertificateServices() {
  const [opened, { toggle }] = useDisclosure(false);
  const { data: servicesRes, isLoading } = useGetAllServices();
  const { countryData } = useCurrentCountry();
  const [servicesToDisplay, setServicesToDisplay] = useState(3);

  const updatedCertificates = () => {
    return servicesRes?.data.map((service: any) => {
      // Find matching certificate based on ServiceCode
      const certsData = certificates.find((certificate) => {
        return service.serviceCode === certificate.ServiceCode;
      });

      // If certsData is found, extend service with additional fields, otherwise return service as is
      if (certsData) {
        return {
          ...service,
          duration: certsData?.Duration,
          image: certsData?.Image,
          link: certsData?.Link,
        };
      }

      // Return the original service object if no match is found
      return service;
    });
  };

  const servicesUpdated = updatedCertificates();
  const visibleServices = servicesUpdated?.slice(0, servicesToDisplay);

  // const [servicesToDisplay, setServicesToDisplay] = useState();

  function handleToggle() {
    toggle();
    if (opened) {
      setServicesToDisplay(3);
    } else {
      setServicesToDisplay(
        (prevCount) => prevCount + servicesUpdated.length + 1
      );
    }
  }

  return (
    <GreyBackground>
      <Container size={"lg"}>
        <Stack align={"center"}>
          <LandingSectionHeader
            headerText="Certificate Verification Services"
            subText="Our solution stands out with its versatility, accommodating various "
          />
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3 }}
            spacing={"xl"}
            mt={50}
            mb={32}
          >
            {visibleServices?.map((service: any) => (
              <PriceCard
                key={service.id}
                actionTexts={[service.duration, service.description]}
                imgSrc={service.image}
                serviceType={service.name}
                price={
                  countryData === "NG"
                    ? {
                        amount: service.nairaAmount,
                        currency: "N",
                      }
                    : {
                        amount: service.dollarAmount,
                        currency: "$",
                      }
                }
                to={service.link}
              />
            ))}
          </SimpleGrid>

          <Button
            size="md"
            td={"none"}
            radius="0.5em"
            fullWidth={false}
            onClick={handleToggle}
          >
            {opened ? "Show Less " : "Show More"}
          </Button>
        </Stack>
      </Container>
    </GreyBackground>
  );
}
